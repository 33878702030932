import React from 'react';
import './App.css';

import Router from './controler/routes/router';

export default function App() {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Router />
    </div>
  );
}
